<template>
  <b-row class="mt-1">
    <b-col cols="9">
      <b-row class="mt-1">
        <b-col cols="6">
          <label class="col-form-label-sm field-name-class">
            {{ $t('components.form.subscriptions.new.rate-authorisation') }}
            <pop-over-notification
              popover-id="rates-authorisation-create"
            />
          </label>
        </b-col>
        <b-col cols="6">
          <select
            v-model="subscriptionPlan.networkEditable"
            :disabled="isDisabled"
            size="sm"
            class="background-light-blue-inputs form-control"
          >
            <option selected :value="true">
              {{ $t('components.form.subscriptions.new.rate-all-users') }}
            </option>
            <option :value="false">
              {{ $t('components.form.subscriptions.new.rate-network-users') }}
            </option>
          </select>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
export default {
  components: {
    PopOverNotification: () => import('@custom/PopOverNotification')
  },
  props: {
    subscriptionPlan: {
      type: Object,
      default: () => {
      }
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
